import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Img from "gatsby-image";
import Layout from "../components/Layout";
import { Flex, Box } from "../components/FlexBox";

import MidTextSection from "../components/sections/MidTextSection";
import { Paragraph, H3, ParagraphDiv } from "../components/parts/typography";

const UL = styled.ul`
    list-style-type: none;
    li {
        display: flex;
        span {
            min-width: 65px;
            display: inline-block;
        }
    }
`;

const Profil = ({ data }) => {
    const Img1 = data.profilImg.childImageSharp.fluid;
    return (
        <Layout contentTopMargin="0">
            <Flex
                justifyContent="center"
                px="20px"
                width="calc(100% + 40px)"
                mx="-20px"
            >
                <Flex maxWidth="872px" flexDirection="column">
                    <Box mb={["64px", "115px"]}>
                        <MidTextSection
                            paragraphTitleDiffer
                            headline="BÜRO"
                            border="none"
                        >
                            <Paragraph>
                                Das von Klaus Kaiser 1995 gegründete
                                Architekturbüro realisiert heute als IGK BERLIN
                                MBH Vorhaben in den Bereichen Wohn- und
                                Gewerbebauten.
                            </Paragraph>
                            <Paragraph mt="24px">
                                Das Büro hat sich seither konsequent als
                                leistungsfähiges, kompetentes und zuverlässiges
                                Team für die Bearbeitung komplexer Aufgaben
                                etabliert. Mitarbeiterstruktur und
                                Büroausstattung erlauben uns eine qualitativ
                                hochwertige sowie zügige Abwicklung von
                                Projekten unterschiedlichster Art und
                                Größenordnung.
                            </Paragraph>
                            <Paragraph mt="24px">
                                Unser Ansatz, das Neue in Bezug und mit
                                Rücksichtnahme auf vorhandene bauliche
                                Strukturen zu sehen und entsprechend
                                einzuordnen, geht einher mit sorgfältiger und
                                anspruchsvoller Detailausbildung. Ein
                                Schwerpunkt unserer Leistungen ist die
                                Erstellung von einer ausführungsreifen
                                Gebäudeplanung, dabei stützt sich unsere
                                fachliche Kompetenz auf langjährige Erfahrungen
                                mit komplexen Projekten. Diese Aufgabe stellt
                                einen hohen Anspruch an die Erkundung von
                                planerischen Grundlagen, die Erstellung und
                                Auswertung des Abwägungsmaterials der gestellten
                                Aufgabe und die Entwicklung von Konzeptideen
                                dar.
                            </Paragraph>
                        </MidTextSection>
                    </Box>
                    <Box mb={["64px", "115px"]}>
                        <MidTextSection
                            paragraphTitleDiffer
                            headline="Unsere Kunden"
                            border="none"
                        >
                            <Paragraph>
                                Die persönliche und umfassende Begleitung
                                unserer Auftraggeber durch alle Phasen des
                                Planens und Bauens ist das Ziel unserer Arbeit.
                                Sowohl die Qualität der erbrachten Leistungen
                                als auch größtmögliche Termin- und
                                Kostensicherheit, ohne dabei unseren
                                architektonischen Anspruch aus den Augen zu
                                verlieren, sind unser Credo.
                            </Paragraph>
                            <Paragraph mt="24px">
                                Wir begleiten Sie auf diesem Weg von der ersten
                                Fragestellung oder Idee bis über die
                                Fertigstellung der Gebäude hinaus. In diesem
                                Kontext verstehen wir Architektur - neben
                                unserem Engagement für die entwurfliche Qualität
                                jedes Projektes - als umfassende
                                Dienstleistungsaufgabe. Unsere Kunden sind
                                Wohnungsbaugesellschaften, Generalübernehmer,
                                Firmen Fertighaus Bau, Immobilienunternehmen und
                                Privatpersonen.
                            </Paragraph>
                            <Paragraph mt="24px">
                                Bei Bedarf übernehmen wir als Generalplaner
                                sämtliche Planungsleistungen eines Projektes und
                                koordinieren als allein verantwortlicher
                                Ansprechpartner des Auftraggebers die
                                Projektabwicklung.
                            </Paragraph>
                        </MidTextSection>
                    </Box>
                    <Box mb={["26px", "36px"]}>
                        <MidTextSection
                            paragraphTitleDiffer
                            headline="BÜROLEISTUNGEN"
                            border="none"
                            my="40px"
                        >
                            <Paragraph>
                                Projektentwicklung, Projektsteuerung
                                <br />
                                Bauherrenbetreuung
                                <br />
                                Planung für Gebäude und Raumbildern Ausbauten -
                                Alle Leistungsphasen der HOAI § 15
                                <br />
                                Sachverständiger für barrierefreie
                                Gebäudeplanung
                            </Paragraph>
                        </MidTextSection>
                    </Box>
                    <Flex>
                        <Img fluid={Img1} style={{ width: "100%" }} />
                    </Flex>
                    <Paragraph mt="20px">
                        Teilnahme am Wettbewerb für den Neubau einer Werkstatt
                        für behinderte Menschen in Berlin Lichtenberg. Der
                        Entwurf verbindet den ganzheitlichen
                        Eingliederungsauftrag mit den therapeutischen und
                        pflegerischen Leistungen.
                    </Paragraph>
                    <Box mt={["64px", "82px"]}>
                        <MidTextSection
                            paragraphTitleDiffer
                            headline="TEAM"
                            border="none"
                            my="40px"
                        >
                            <H3>
                                Geschäftsführender Gesellschafter Architekt
                                Klaus Kaiser
                            </H3>

                            <UL>
                                <li>
                                    <span>1983 - 86</span>
                                    <span>Ausbildung zum Zimmermann</span>
                                </li>
                                <li>
                                    <span>1986 - 88</span>
                                    <span>Wandergeselle als Zimmermann</span>
                                </li>
                                <li>
                                    <span>1988 - 89</span>
                                    <span>FH Kiel - Architekturstudium</span>
                                </li>
                                <li>
                                    <span>1991 - 95</span>
                                    <span>
                                        FH Berlin - Architekturstudium mit
                                        Abschluss Dipl. Ing. der Fachrichtung
                                        Architektur Bauplanung
                                    </span>
                                </li>
                                <li>
                                    <span>1995</span>
                                    <span>
                                        Gründung des Ingenieur- und
                                        Planungsbüros Kaiser
                                    </span>
                                </li>
                                <li>
                                    <span>2019</span>
                                    <span>
                                        Gründung der Architekten- und
                                        Ingenieurgesellschaft Kaiser mbh
                                    </span>
                                </li>
                            </UL>

                            <ParagraphDiv mt="40px">
                                <H3>Büroleiter Architekt Dirk Pfeiffer</H3>
                                <UL>
                                    <li>
                                        <span>1995 - 03</span>
                                        <span>
                                            TU Berlin - Architekturstudium mit
                                            Abschluss Dipl. Ing. der
                                            Fachrichtung Architektur
                                        </span>
                                    </li>
                                    <li>
                                        <span>2003 - 07</span>
                                        <span>
                                            Tätigkeit als angestellter
                                            Dipl.-Ing- Architektur
                                        </span>
                                    </li>
                                    <li>
                                        <span>2007 - 09</span>
                                        <span>
                                            Dipl.-Ing. Architektur im Ingenieur-
                                            und Planungsbüro Kaiser
                                        </span>
                                    </li>
                                    <li>
                                        <span>2009 - 18</span>
                                        <span>
                                            Dipl.-Ing. Architektur in
                                            verschiedenen Architekturbüros
                                        </span>
                                    </li>
                                    <li>
                                        <span>seit 2019</span>
                                        <span>
                                            Architekt in der Architekten- und
                                            Ingenieurgesellschaft Kaiser mbh
                                        </span>
                                    </li>
                                </UL>
                            </ParagraphDiv>
                            <Paragraph mt="40px">
                                Unsere Mitarbeiter:innen sind teils lange Jahre
                                in unserem Büro tätig und planen mit viel
                                Engagement seit Jahren in allen Leistungsphasen
                                vorwiegend in der Region Berlin/Brandenburg.
                            </Paragraph>
                        </MidTextSection>
                    </Box>
                </Flex>
            </Flex>
        </Layout>
    );
};

export default Profil;

export const ImageQuery = graphql`
    query {
        profilImg: file(
            relativePath: { eq: "profil/behinderten_werkstatt_lichtenberg.jpg" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`;
