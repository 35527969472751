import React from "react";
import { Flex, Box } from "../FlexBox";
import { ParagraphDiv, Headline } from "../parts/typography";
import { colors } from "../../utilities/colors";

function MidTextSection({ children, ...props }) {
    return (
        <Flex height={props.height}>
            <Box flex="1 0 100%">
                {props.headline && (
                    <Box
                        borderTop={props.borderTop && `6px solid ${colors.red}`}
                        display="inline-block"
                    >
                        <Headline>{props.headline}</Headline>
                    </Box>
                )}

                <ParagraphDiv>{children}</ParagraphDiv>
            </Box>
        </Flex>
    );
}

export default MidTextSection;
